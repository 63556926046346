import { OverlayTrigger, Popover } from "react-bootstrap";
import {useTranslation} from 'react-i18next';
function PopoverUi({name , descritpion=true}) {
  const {t} = useTranslation();
  const popoverBottom = (
    <Popover
      id="popover-positioned-bottom"
      title="Popover bottom"
      style={{
        background: '#eee',
        fontSize: '1rem',
        border: 'none',
        padding: '1rem',
      }}>
      <span className="secondry-color">{name}</span> { descritpion && t('currentUserUsed') }
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="bottom" overlay={popoverBottom}>
      <img src="/icons/info.png" style={{ width: '20px', height: '20px' , cursor: 'pointer' }} />
    </OverlayTrigger>
  );
}

export default PopoverUi;
