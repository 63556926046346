import { Col, Container, Row } from "react-bootstrap";
import MainButton from "../UI/main-button/MainButton";

const NotPublished = () => {
  

  return (
   <Container>
    <Row>
      <Col md={{span: 6, offset: 3}} className="text-center pt-5 pb-5">
        <img src="/not-available.jpeg" alt="img" />
        <h3 className="not-available-title">This program is no longer available</h3>
        <div className="mt-5" onClick={() => { window.location.href = '/'  } }>
          <MainButton text="back to home" />
        </div>
      </Col>
    </Row>
   </Container>
  );
};

export default NotPublished;
