import {Col, Container, Row} from 'react-bootstrap';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/AddAvailability.module.css';
import {useEffect, useState} from 'react';
import CustomSelectRadio from '../../UI/custom-select-radio/CustomSelectRadio';
import MultiRangeCalendar from './components/MultiRangeCalendar';
import MultiSelectCalendar from './components/MultiSelectCalendar';
import moment from 'moment';
import {getPrices, sendBlocks} from './Availability.service';
import {getDaysArray} from '../../helpers/getDaysArray';
import {useNavigate} from 'react-router-dom';
import {useAppDispatch} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
const AddAvailability = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [availabiltyDurationType, setavailabiltyDurationType] = useState<
    string | null
  >(null);
  const [availabiltyDaysType, setavailabiltyDaysType] = useState('by-range');
  const [errorMessage, setErrorMessage] = useState('');
  const [startTime, setStartTime] = useState<any>('');
  const [endTime, setEndTime] = useState<any>('');
  const [multiDateRange, setMultiDateRange] = useState(null);
  const [multiSelectDate, setMultiSelectDate] = useState(null);
  const [selectedDays, setSelectedDays] = useState<any>([]);
  useEffect(() => {
    ReadData();
  }, []);

  const [duration15, setDuration15] = useState(false);
  const [duration30, setDuration30] = useState(false);
  const [duration60, setDuration60] = useState(false);
  const [duration90, setDuration90] = useState(false);
  const [duration120, setDuration120] = useState(false);
  const [duration150, setDuration150] = useState(false);
  const [duration180, setDuration180] = useState(false);

  const handleRadioChange = (value: any) => {
    setavailabiltyDaysType(value);
    setSelectedDays([]);
  };

  const getTimeInMinutes = (time: string) => {
    const [hours, minutes] = time.split(':');
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  };

  const handleButtonClick = async () => {
    if (!startTime) {
      setErrorMessage('Start time is required.');
    } else if (!endTime) {
      setErrorMessage('End time is required.');
    } else if (getTimeInMinutes(endTime) - getTimeInMinutes(startTime) <= 0) {
      setErrorMessage('End time should be greater than start time.');
    } else if (!availabiltyDurationType) {
      setErrorMessage('Session duration is required.');
    } else if (selectedDays.length == 0) {
      setErrorMessage(`select days`);
    } else if (!checkSlotPeriod()) {
      setErrorMessage(`slotSmPeriod ${availabiltyDurationType} ${'minutes'}`);
    } else if (!checkValidWithPeriod()) {
      setErrorMessage(
        'Please select session period to match the inserted working time , [Ex. From 1 PM to 5 PM, then session period may be 30 mins or 60 mins]',
      );
    } else {
      setErrorMessage('');
      dispatch(setLoading(true));
      let data = new FormData();
      data.append('from', moment(startTime, 'HH:mm').format('HH:mm:ss'));
      data.append('to', moment(endTime, 'HH:mm').format('HH:mm:ss'));
      data.append('duration', availabiltyDurationType);
      if (availabiltyDaysType == 'by-range') {
        getDaysArray(selectedDays)?.map((i: any, index: number) => {
          data.append(`days[${index}]`, i);
        });
      } else {
        selectedDays?.map((i: any, index: number) => {
          data.append(
            `days[${index}]`,
            moment.unix(i.unix).format('YYYY-MM-DD'),
          );
        });
      }

      let result = await sendBlocks(data);
      if (result.response.status) {
        dispatch(setLoading(false));
        navigate(-1);
      } else {
        dispatch(setLoading(false));
        setErrorMessage(
          "There are some sessions that we can't add as their times intersected with previously added sessions",
        );
      }
    }
  };

  const checkTimeDiff = () => {
    if (endTime != '' && startTime != '') {
      return getTimeInMinutes(endTime) - getTimeInMinutes(startTime);
    }
    return 0;
  };

  const ReadData = async () => {
    try {
      let result = await getPrices();
      if (result.response.status && result.response.data) {
        if (
          result?.response?.data?.duration15 &&
          result?.response?.data?.duration15 !== 0
        ) {
          setDuration15(true);
        }
        if (
          result?.response?.data?.duration30 &&
          result?.response?.data?.duration30 !== 0
        ) {
          setDuration30(true);
        }
        if (
          result?.response?.data?.duration60 &&
          result?.response?.data?.duration60 !== 0
        ) {
          setDuration60(true);
        }
        if (
          result?.response?.data?.duration90 &&
          result?.response?.data?.duration90 !== 0
        ) {
          setDuration90(true);
        }
        if (
          result?.response?.data?.duration120 &&
          result?.response?.data?.duration120 !== 0
        ) {
          setDuration120(true);
        }
        if (
          result?.response?.data?.duration150 &&
          result?.response?.data?.duration150 !== 0
        ) {
          setDuration150(true);
        }
        if (
          result?.response?.data?.duration180 &&
          result?.response?.data?.duration180 !== 0
        ) {
          setDuration180(true);
        }
      }
    } catch (e) {
      console.log({e});
    }
  };

  const checkSlotPeriod = () => {
    let TheEnd =
      moment(endTime, 'HH:mm').locale('en').format('HH:mm') == '00:00'
        ? moment(endTime, 'HH:mm').locale('en').add(1, 'days')
        : endTime;

    const diffMin = moment
      .duration(moment(TheEnd, 'HH:mm').diff(moment(startTime, 'HH:mm')))
      .asMinutes();

    return diffMin >= Number(availabiltyDurationType);
  };
  const checkValidWithPeriod = () => {
    let TheEnd =
      moment(endTime, 'HH:mm').format('HH:mm') == '00:00'
        ? moment(endTime, 'HH:mm').add(1, 'd')
        : endTime;
    const diffMin = moment
      .duration(moment(TheEnd, 'HH:mm').diff(moment(startTime, 'HH:mm')))
      .asMinutes();

    return (diffMin / Number(availabiltyDurationType)) % 1 === 0;
  };
  function generateTimeIntervals() {
    const intervals = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        intervals.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    return intervals;
  }
  const timeIntervals = generateTimeIntervals();
  return (
    <div className="mt-md-5">
      <Container className={classes.FormContainer}>
        <h3 className="w-100 mb-4 mt-3 font-weight-bold text-center">
          Add Availability
        </h3>
        <Row className="d-flex justify-content-between">
          <div className={classes.FormPadding}>
            <Row>
              <Col md={{span: 6, offset: 3}}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">
                    <span style={{color: 'red'}}>* </span>Start Time
                  </label>
                  <select
                    value={startTime}
                    className={`form-control m-0 ${classes.FormInput}`}
                    onChange={e => setStartTime(e.target.value)}>
                    <option value="" disabled>
                      Select a time
                    </option>
                    {timeIntervals.map(interval => (
                      <option key={interval} value={interval}>
                        {moment(interval, 'HH:mm').format('hh:mm A')}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col md={{span: 6, offset: 3}}>
                <div className="mt-3 mb-3">
                  <label className="mb-2">
                    <span style={{color: 'red'}}>* </span>End Time
                  </label>
                  <select
                    value={endTime}
                    className={`form-control m-0 ${classes.FormInput}`}
                    onChange={e => setEndTime(e.target.value)}>
                    <option value="" disabled>
                      Select a time
                    </option>
                    {timeIntervals.map(interval => (
                      <option key={interval} value={interval}>
                        {moment(interval, 'HH:mm').format('hh:mm A')}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>

              <Col md={{span: 6, offset: 3}}>
                <h6 className="mt-2">Session Duration</h6>

                <div className="mt-3 mb-3">
                  <Row>
                    {duration15 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="15 Minutes"
                          forId={'15'}
                          disabled={checkTimeDiff() < 15}
                          value={'15'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '15'}
                        />
                      </Col>
                    )}
                    {duration30 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="30 Minutes"
                          forId={'30'}
                          disabled={checkTimeDiff() < 30}
                          value={'30'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '30'}
                        />
                      </Col>
                    )}
                    {duration60 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="60 Minutes"
                          forId={'60'}
                          disabled={checkTimeDiff() < 60}
                          value={'60'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '60'}
                        />
                      </Col>
                    )}
                    {duration90 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="90 Minutes"
                          forId={'90'}
                          disabled={checkTimeDiff() < 90}
                          value={'90'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '90'}
                        />
                      </Col>
                    )}
                    {duration120 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="120 Minutes"
                          forId={'120'}
                          disabled={checkTimeDiff() < 120}
                          value={'120'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '120'}
                        />
                      </Col>
                    )}
                    {duration150 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="150 Minutes"
                          forId={'150'}
                          disabled={checkTimeDiff() < 150}
                          value={'150'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '150'}
                        />
                      </Col>
                    )}
                    {duration180 && (
                      <Col xs={12}>
                        <CustomSelectRadio
                          name="periods-items"
                          text="180 Minutes"
                          forId={'180'}
                          disabled={checkTimeDiff() < 180}
                          value={'180'}
                          onRadioChange={(value: string) =>
                            setavailabiltyDurationType(value)
                          }
                          checked={availabiltyDurationType == '180'}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>

              <Col md={{span: 6, offset: 3}}>
                <h6 className="mt-2">Select The Availability Days By</h6>

                <div className="mt-3 mb-3">
                  <Row>
                    <Col xs={6}>
                      <CustomSelectRadio
                        name="select-period"
                        text="Period Of Time"
                        forId={'by-range'}
                        disabled={false}
                        value={'by-range'}
                        onRadioChange={handleRadioChange}
                        checked={availabiltyDaysType == 'by-range'}
                        onClick={() => {
                          setavailabiltyDaysType('by-range');
                          setSelectedDays([]);
                        }}
                      />
                    </Col>

                    <Col xs={6}>
                      <CustomSelectRadio
                        name="select-period"
                        text="Select Multi Days"
                        forId={'by-days'}
                        disabled={false}
                        value={'by-days'}
                        onRadioChange={handleRadioChange}
                        checked={availabiltyDaysType == 'by-days'}
                        onClick={() => {
                          setavailabiltyDaysType('by-days');
                          setSelectedDays([]);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>

              <Col md={{span: 6, offset: 3}}>
                {availabiltyDaysType == 'by-range' ? (
                  <MultiRangeCalendar
                    setDays={setSelectedDays}
                    selectedDays={selectedDays}
                  />
                ) : (
                  <MultiSelectCalendar
                    setDays={setSelectedDays}
                    selectedDays={selectedDays}
                  />
                )}
              </Col>

              <Col md={{span: 6, offset: 3}}>
                <div className="mt-4 mb-2 danger-title">{errorMessage}</div>
              </Col>

              <Col md={{span: 6, offset: 3}}>
                <div className="mt-4 mb-2" onClick={handleButtonClick}>
                  <MainButton text="Confirm" />
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default AddAvailability;
