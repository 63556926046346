import {
  ButtonToolbar,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import {FaCircle, FaLongArrowAltRight, FaRegCalendarAlt} from 'react-icons/fa';
import Modal from 'react-bootstrap/Modal';
import MainButton from '../../UI/main-button/MainButton';
import classes from './style/UserTrackingSystem.module.css';
import {NavLink, useLocation, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from '../../hooks/hooks';
import {setLoading} from '../../store/userSlice';
import {
  enrollFree,
  addCart,
  assignAndStartTrack,
} from '../Tracking/Trackings.service';

import {getmemberForAssign} from './Initiatives.service';

import {getTrackingsDetailsBeforeEnroll} from './Initiatives.service';

import {isUserLoggedIn, getUserId} from '../../auth/utiles';
import {useNavigate} from 'react-router-dom';
import './style/VideoPlayer.css';
import CancelButton from '../../UI/cancel-button/CancelButton';
import CustomCheckbox from '../Tracking/components/CustomCheckbox';
import ShareIcon from '../../components/share-icon/ShareIcon';
import { getDynamicLinkDomain } from '../../helpers/getDynamicLinkDomain';
import ReactQuill from 'react-quill';
import moment from 'moment';
import SecondryButton from '../../UI/secondry-button/SecondryButton';

const TrackingDetails = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isLevel = queryParams.get('is_level') || '0';
  const current_user_id = queryParams.get('current_user_id');
  const hasPricedLevel = queryParams.get('has_priced_level') || '1';

  const navigate = useNavigate();

  const isLoggedIn = isUserLoggedIn();

  const userId = getUserId() ?? '';

  const cartData = useAppSelector(state => state.cart.data);

  const {id} = useParams();

  const dispatch = useAppDispatch();

  const [tracking, setTracking] = useState(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const [enrolled, setEnrolled] = useState(false);

  const [show, setShow] = useState(false);

  const [showAssignUsers, setShowAssignUsers] = useState(false);
  

  const [showTrakingTerms, setShowTrakingTerms] = useState(false);

  const[acceptTerms , setAcceptTerms ] = useState(false);


  const [showPriceLimitation, setShowPriceLimitation] = useState(false);

  

  const [members, setMembers] = useState([]);

  const [member, setMember] = useState(null);

  const [selectedValues, setSelectedValues] = useState([]);

  const [unlimited, setUnLimited] = useState(false);

  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    document.getElementById('video').play();
  };

  const existInCart = () => {
    return cartData.find(i => i.name == tracking.name);
  };

  const enroll = async () => {
    dispatch(setLoading(true));
    try {
      let data = new FormData();

      //if( getSelectedAssignedId ){
        //data.append(`users_ids[0]`, getSelectedAssignedId)
      //}else{
        destinationArray.map((i, index) => data.append(`users_ids[${index}]`, i.id));
      //}

      
      data.append('module_name', 'Tracking');
      data.append('module_id', String(id));

      if(  queryParams.get('type') || queryParams.get('modal_type') ){
        let type = ''
        if( queryParams.get('type') ){
          type = queryParams.get('type')
        } else{
          type = tracking?.type == 'initiative' ? 'Initiative' : 'tracking'
        }
        let invitationId = queryParams.get('invitation_id') 
        

        data.append('invitation_id', invitationId);
        if( queryParams.get('invitation_id') && 
        ( ( parseInt(invitationId) == tracking?.id) || (parseInt(invitationId) == tracking?.package_id ) )
        ){
          data.append('invitation_user_id', queryParams.get('invitee_id') );
        }
        data.append('invitation_type', queryParams.get('modal_type') ? queryParams.get('modal_type') : type );
        
        
      }


      let result = await enrollFree(data);
      if (result.response) {
        setEnrolled(true);
        dispatch(setLoading(false));
        setShow(false);

        if( tracking?.type == 'initiative' ){
          navigate('/user/initiatives')
        }else{
          navigate('/user/trackings/list/')
          
        }
      }
    } catch (e) {
      console.log({e});
    }
  };

  const addToCart = async () => {
    if (isLoggedIn) {
      dispatch(setLoading(true));
      try {
        let data = new FormData();
        //if( getSelectedAssignedId ){
          //data.append('model_id', String(id));
          //data.append('model_name', 'Tracking');

          //data.append(`items[0][model_id]`, String(id));
          //data.append(`items[0][model_name]`, 'Tracking');
          //data.append(`items[0][user_id]`, getSelectedAssignedId);

        //}else{
          for (let i = 0; i < destinationArray.length; i++) {
            data.append('model_id', String(id));
            data.append('model_name', 'Tracking');

            data.append(`items[${i}][model_id]`, String(id));
            data.append(`items[${i}][model_name]`, 'Tracking');
            data.append(`items[${i}][user_id]`, destinationArray[i].id);
          }
        //}
        if( tracking?.type == 'tracking' ){
          data.append('is_unlimited', unlimited ? 1 : 0);
        }
        if(  queryParams.get('type') || queryParams.get('modal_type') ){
          let type = ''
          if( queryParams.get('type') ){
            type = queryParams.get('type')
          } else{
            type = tracking?.type == 'initiative' ? 'Initiative' : 'tracking'
          }
          let invitationId = queryParams.get('invitation_id') 
          
          
  
          data.append('invitation_id', invitationId);
          if( queryParams.get('invitation_id') && 
          ( ( parseInt(invitationId) == tracking?.id) || (parseInt(invitationId) == tracking?.package_id ) )
          ){
            data.append('invitation_user_id', queryParams.get('invitee_id') );
          }
          data.append('invitation_type', queryParams.get('modal_type') ? queryParams.get('modal_type') : type );
          
          
        }
        
        let result = await addCart(data);
        console.log('resultresultresultresultENROL', result);
        dispatch(setLoading(false));
        setShowAssignUsers(false);
        //window.location.reload()
        navigate('/cart')
      } catch (e) {
        console.log({e});
      }
    } else {
      navigate(`/login`);
    }
  };

  useEffect(() => {
    getData();
    getMembers();
  }, []);

  useEffect(() => {
    if( tracking ){
      createDynamicLink()
    }
  }, [tracking]);

  const getData = async () => {
    dispatch(setLoading(true));
    let result = await getTrackingsDetailsBeforeEnroll(id);
    let record = result.response
      if( record &&  record.not_available ){
        navigate('/not-available')
      }if( record && record.not_published   ){
        navigate('/not-published')
      }
      else{
        setTracking(result.response.data);
      }

    dispatch(setLoading(false));
  };

  const getMembers = async () => {
    let result = await getmemberForAssign(id);

    if (result.response?.data.length > 0) {
      console.log('assign users', result.response.data);

      let data = result.response.data

      let newData = data.map(item => ({ ...item, checked: false }));

      newData = newData.filter( item => item.in_cart == 0 && item.is_enrolled == 0 )

      setSourceArray(newData);

      let inCartArray = data.filter( item => item.in_cart == 1 )

      setInCartArray(inCartArray)

      
      let inSubcribeArray = data.filter( item => item.is_enrolled == 1 )
      setInSubcribeArray(inSubcribeArray)
    }
  };

  const handleCheckboxChange = event => {
    const {value, checked} = event.target;
    console.log('value, checked', value, checked);
    if (checked) {
      // If checkbox is checked, add the value to the selectedValues array
      setSelectedValues(prevSelectedValues => [...prevSelectedValues, value]);
    } else {
      // If checkbox is unchecked, remove the value from the selectedValues array
      setSelectedValues(prevSelectedValues =>
        prevSelectedValues.filter(item => item !== value),
      );
    }

    console.log('selectedValues', selectedValues);
    //const selectedObjects = checkboxes.filter(obj => selectedValues.includes(obj.value));
    //console.log('selectedObjects' , selectedObjects )
  };

  const Assign = async () => {
    if (member) {
      console.log('data');
      console.log('id ', id);
      console.log(
        'tracking.user_tracking_enrollment_id',
        tracking.user_tracking_enrollment_id,
      );
      console.log('member ', member);
      let data = new FormData();
      data.append('tracking_id', id);
      if (tracking?.user_tracking_enrollment_id) {
        data.append(
          'user_tracking_enrollment_id',
          tracking.user_tracking_enrollment_id,
        );
      }
      data.append(`members[0]`, member);
      let result = await assignAndStartTrack(data);
      console.log('result', result);
      setShowAssignUsers(false);
    }
  };

  const popoverBottom = (
    <Popover
      id="popover-positioned-bottom"
      title="Popover bottom"
      style={{
        background: '#eee',
        fontSize: '1rem',
        border: 'none',
        padding: '1rem',
      }}>
      Limited - Only you or a family member can start this regimen and you can
      not re-take it after you have finished it.
    </Popover>
  );

  const [sourceArray, setSourceArray] = useState([]);

  const [destinationArray, setDestinationArray] = useState([]);

  const [selectAllCheck, setSelectAllCheck] = useState(false);

  const [activePopUpTab, setActivePopUpTab] = useState('added-to-cart');

  const [inCartArray, setInCartArray] = useState([]);

  const [inSubcribeArray, setInSubcribeArray] = useState([]);

  const toggleSelectAll = () => {
    setSelectAllCheck(!selectAllCheck);
  
    const allSelected = sourceArray.every(item => item.checked);
  
    // Toggle all items to checked or unchecked based on the current state
    const updatedSourceArray = sourceArray.map(item => ({
      ...item,
      checked: !allSelected,
    }));
  
    // Update destinationArray based on the selection
    const updatedDestinationArray = !allSelected
      ? updatedSourceArray
      : [];
  
    setSourceArray(updatedSourceArray);
    setDestinationArray(updatedDestinationArray);
  };
  
  const handleCheckboxChangeItems = (id) => {
    // Find the item in the source array
    const selectedItem = sourceArray.find(item => item.id === id);
  
    if (selectedItem) {
      // Toggle the checked status
      selectedItem.checked = !selectedItem.checked;
  
      // Update the source array's checked status
      const updatedSourceArray = sourceArray.map(item =>
        item.id === id ? { ...item, checked: selectedItem.checked } : item
      );
  
      // Check if the item should be added or removed from destinationArray
      setDestinationArray(prevArray => {
        if (selectedItem.checked) {
          // If checked, add to destinationArray if not already present
          return [...prevArray, selectedItem];
        } else {
          // If unchecked, remove from destinationArray
          return prevArray.filter(item => item.id !== id);
        }
      });
  
      // Check if all items are checked to update the "Select All" checkbox
      const allItemsChecked = updatedSourceArray.every(item => item.checked);
      setSelectAllCheck(allItemsChecked);
  
      // Update the source array state
      setSourceArray(updatedSourceArray);
    }
  };

  const handleCheckboxChangeItemsForTracking = id => {
    // Find the item in the source array
    const selectedItem = sourceArray.find(item => item.id === id);

    if (selectedItem) {
      selectedItem.checked = true;
      // Update checked status in the source array
      const updatedSourceArray = sourceArray.map(item =>
        item.id === id ? {...item, checked: !item.checked} : item,
      );

      // Update the state with the modified source array
      const newSourceData = sourceArray.filter(item => item.id !== id);
      setSourceArray(newSourceData);

      setDestinationArray(prevArray => [...prevArray, selectedItem]);
    }
  };

  const handleDestinationCheckboxChangeItems = id => {
    // Find the item in the source array
    const selectedItem = destinationArray.find(item => item.id === id);

    if (selectedItem) {
      selectedItem.checked = false;

      setSourceArray(prevArray => [...prevArray, selectedItem]);

      // Update the state with the modified source array
      const newDestinationData = destinationArray.filter(
        item => item.id !== id,
      );

      setDestinationArray(newDestinationData);
    }
  };

  const [url, setUrl] = useState('https://championsacademy.page.link');

  
  const createDynamicLink = async () => {
    const requestBody = {
      dynamicLinkInfo: {
        domainUriPrefix: 'https://championsacademy.page.link',
        link: `${getDynamicLinkDomain()}/?type=${tracking?.type == 'initiative' ? 'Initiative' : 'tracking' }&model_id=${id}&invitation_id=${tracking?.id}${ tracking?.has_enrollment == 0 ? `&invitee_id=${localStorage.getItem("id") }` : '' }`,
        androidInfo: {
          androidPackageName: 'com.championsacademy',
        },
        iosInfo: {
          iosBundleId: 'org.Champions.app',
        },
      },
    };

    try {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyBlnJ9M_bXXKck16qjoInYIRo0xFp5pPPU`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestBody),
        },
      );

      const data = await response.json();
      console.log('dynamic link,', data);
      // return the short link
      setUrl(data.shortLink);
    } catch (error) {
      console.error('Error creating dynamic link: ', error);
      throw error;
    }
  };

  const getSelectedAssignedId = queryParams.get('selectedAssignedId')

  return (
    <div className='gray-bg'>
      {tracking ? 
      <Container className="pt-5 pb-5">
        <Row>

          
          <Col md={6} className={classes.TrackingDetails}>
            <div>
              <div className='d-flex justify-content-end'>
              {  tracking?.type == 'initiative' && ( (tracking?.has_priced_level  == 1 && isLevel == '1') || isLevel == '0' ) && <ShareIcon url={url} hasInvite={true} /> }
              </div>

              <div className="bg-white rounded p-3 mt-2">
              
                <h3 className="mb-2">{tracking?.name}</h3>

                <div className="mb-2">
                  <div className="d-flex justify-content-between">
                    
                    { tracking?.type == 'tracking' && tracking?.has_unlimited_price == 0 ? 
                    <div style={{fontSize: '1.2rem'}}>
                      {tracking?.price == 0 && 'Free'}
                      {tracking?.price == 1 && (
                        <span
                          style={{fontWeight: '600'}}
                          className="text-dark mr-1 ml-1">
                          <img
                            src={'/icons/coin.png'}
                            alt="course-img"
                            style={{
                              marginRight: '10px',

                              verticalAlign: 'sub',
                            }}
                          />
                          <span> {tracking?.price} {tracking?.user_currency}</span>
                        </span>
                      )}
                    </div>
                    : '' }

                    { tracking?.type == 'tracking' && tracking?.has_unlimited_price == 1 ? 
                    <div style={{fontWeight: '600'}} className="text-dark">
                    <div className='mt-2' style={{ fontSize: '24px' }}>
                      <img
                        src={'/icons/coin.png'}
                        alt="course-img"
                        style={{
                          
                          width: '25px',
                          verticalAlign: 'middle',
                        }}
                      />
      
                      <span style={{ margin: '0 5px' }}>limited</span>
                      <span> {tracking?.price} {tracking?.user_currency}</span>
                      <span> - </span>
                      <span>unlimited</span>
                      <span> {tracking?.unlimited_price} {tracking?.user_currency}</span>
      
                    </div>
      
                    
                    
                  </div>
                    : ''} 

                  </div>
                </div>

                {  tracking?.type == 'tracking' ?
                  <div className={`mb-3 mt-3`}>
                    <img
                      src={'/icons/clock.jpeg'}
                      alt="course-img"
                      width={27}
                    />
                    <span style={{ margin: '0 5px' , fontSize: '20px' , fontWeight: '600' }}> { tracking?.duration } Days </span>
                  </div>
                :
                ''
                }

                {
                tracking.type != 'tracking' ?
                  <div className='mb-md-3 mt-3  '> 
                    <div className='d-flex align-items-center'>

                      <div className='d-flex align-items-center'>
                        <img src="/calendar_line.svg" alt='img' />
                        <p className='card-custom-date'>{moment(tracking.start_date).format('DD MMM, YYYY')} - {moment(tracking.end_date).format('DD MMM, YYYY')}</p>
                      </div>

                      <div className='d-flex align-items-center mx-4'>
                        <img src="/multi-users.svg" alt='img' />
                        <p className='card-custom-date'>{tracking.enrolled_users_count ?? 0 } Subscirbers </p>
                      </div>

                    </div>

                    <div className='mt-2'>
                      <img src={ tracking.provider_image ?? `/champ-logo.png`} alt='logo' style={{ borderRadius:'50%' , width:'25px' , marginRight: '5px' }} />
                      <span className='main-color'>{tracking.provider_name}</span>
                    </div>
                  </div>
                : '' }

              </div>

              <div className='bg-white rounded p-3 mt-2'>
                <h4 className='mb-3'>About System</h4>
                <ReactQuill theme="bubble" value={tracking?.description} readOnly={true}  />
              </div>

              <div className="bg-white rounded p-3 mt-2 mb-4">
                <h4>Fields</h4>

                <div className="mb-3">
                  {tracking?.tracking_fields_goals.map((d, i) => (
                    <>
                      <p style={{margin: '10px 0 5px'}}>
                        <FaCircle color="fb5456" />
                        <span style={{marginLeft: '10px'}}>{d.name}</span>
                      </p>

                      {d.goals && (
                        <div className="goals" style={{marginLeft: '20px'}}>
                          {d.goals.map((goal, index) => {
                            return (
                              <div>
                                <FaCircle color="#9f9f9f" size={12} />
                                <span style={{marginLeft: '10px'}}>
                                  {goal.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>

              <div>
                {
                (tracking?.price && tracking?.type == 'tracking' )
                ||
                ( tracking?.type == 'initiative' && tracking?.price && isLevel == '1' && tracking?.has_priced_level )
                ||
                ( tracking?.type == 'initiative' && tracking?.price && isLevel == '0' )
                ?
                (
                  <MainButton
                    text={existInCart() ? `${tracking.type == 'initiative' ? 'Subscribe To Competition | ' +  tracking?.price  + ' ' +  tracking?.user_currency  : 'View Cart' }` : `${tracking.type == 'initiative' ? 'Subscribe To Competition | ' + tracking?.price  + ' ' +  tracking?.user_currency : 'Add To Cart' } ` }
                    onClick={() => {
                      if( tracking?.cumulative_score_calculated ){
                        setShowErrorMsg(true)
                      }else{
                        if (existInCart()) {
                          if( tracking.type == 'initiative' ){
                            setShowAssignUsers(true)
                          }else{
                            navigate('/cart');
                          }
                        } else {
                          if( tracking?.has_unlimited_price == 1 ){
                            
                            setShowPriceLimitation(true)
                          }else{
                            if( getSelectedAssignedId ){
                              setShowAssignUsers(true)
                              //addToCart()
                            }else{
                              setShowAssignUsers(true)
                            }
                          }
                          
                        }
                      }
                      
                    }}
                  />
                ) : (
                  ''
                )}
                

                {
                ( tracking?.type == 'tracking' && tracking?.price == 0) 
                ||
                ( tracking?.type == 'initiative' && tracking?.price == 0 && isLevel == '1' && tracking?.has_priced_level )
                ||
                ( tracking?.type == 'initiative' && tracking?.price == 0 && isLevel == '0' )
                ?
                (
                  

                  <div onClick={() => {
                    if( tracking?.cumulative_score_calculated ){
                      setShowErrorMsg(true)
                    }else{
                      if (existInCart()) {
                        navigate('/cart');
                      } else {
                        if( getSelectedAssignedId ){
                          setShowAssignUsers(true)
                          //enroll()
                        }else{
                          setShowAssignUsers(true)
                        }
                        
                      }
                    }
                    }}>
                    <MainButton text="Enroll Now" />
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </Col>

          <Col md={6} className={classes.TrackingMedia}>
            <Card className={classes.TrackVideo}>
              <div className={classes.TrackImgContainer}>
                <div className={classes.CardImgContainer}>
                  {tracking?.intro_path_type == 'video' ? (
                    <>
                      <div className="video-player">
                        <video
                          style={{borderRadius: '15px 15px 0 0'}}
                          id="video"
                          src={tracking?.intro_path}
                          controls
                          autoPlay={isPlaying}
                          onClick={togglePlay}
                        />
                        {!isPlaying && (
                          <div className="overlay">
                            <img
                              src="/icons/play.png"
                              alt="Play Button"
                              onClick={togglePlay}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <img
                      className="card-img-top"
                      alt="courseImg"
                      src={tracking?.intro_path}
                    />
                  )}
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
      : '' }

      

      {/*assign user pop up*/}
      <Modal
        show={showAssignUsers}
        onHide={() => setShowAssignUsers(false)}
        centered>
        { tracking?.type == 'initiative' ?   
        <Modal.Body className='containers-tab'>

       {/*
          <div className='d-flex'>
             <div 
             className={`normal-tab ${activePopUpTab == 'added-to-cart' ? 'active-tab' : ''  } `}
             onClick={ () => { setActivePopUpTab('added-to-cart') } }
             >Added To Cart ({inCartArray.length}) </div>  
             <div 
             className={`normal-tab ${activePopUpTab == 'subscribed' ? 'active-tab' : ''  } `}
             onClick={ () => { setActivePopUpTab('subscribed') } }
             >Subscribed ({inSubcribeArray.length}) </div>  
          </div>
          */}

          <div className='d-flex justify-content-between align-items-center my-2'>
            {
              sourceArray.length
              ?
              <h5 className="secondry-color">Select participants</h5>
              :
              <h5 className="secondry-color"> No member family found</h5>
            }  

            <div 
              onClick={() =>  navigate('/add-child?status=add') }
              className="cursor-pointer"
              style={{color: '#07b2bd'}}
              >
            + Add new member
            </div>

          </div>

          {
            inCartArray.length
            ?
            inCartArray.map((item,i) => (
              <>  
              <div className='d-flex align-items-center justify-content-between mb-3'>
                <div className="d-flex align-items-center" key={item.id} style={{ opacity: '.5' }}>
                  <label className={`mb-4 ${classes.CheckBoxContainer}`}>
                    <input type="checkbox" checked disabled />
                    <span className={classes.Checkmark}></span>
                  </label>
                    
                    <div className='mx-2'>
                        <img 
                        src={item.avatar ?? '/icons/manHolder.png' }
                        alt='image'
                        style={{
                            width: '50px',
                            borderRadius: '50%',
                            height: '50px',
                            margin:'0 20px 0 0',
                        }}
                        />
                        <span>{item.username}</span>
                    </div>

                    <div>

                    </div>    
                </div>

                <p style={{ 'fontWeight' : 'bold' }} className='main-color m-0'>Added To Cart</p>   
                

              </div>
              </>
              ))
              
            :
            
            ''
          } 

           {/*
          {inCartArray.length && activePopUpTab == 'added-to-cart' ? <hr /> : '' } 
          */}

{
            inSubcribeArray.length
            ?
            inSubcribeArray.map((item,i) => (
              <>  
              <div className='d-flex align-items-center justify-content-between mb-3'>
                <div className="d-flex align-items-center" key={item.id} style={{ opacity: '.5' }}>
                  <label className={`mb-4 ${classes.CheckBoxContainer}`}>
                    <input type="checkbox" checked disabled
                   
                    />
                    <span className={classes.Checkmark}></span>
                  </label>
                    
                    <div className='mx-2'>
                        <img 
                        src={item.avatar ?? '/icons/manHolder.png' }
                        alt='image'
                        style={{
                            width: '50px',
                            borderRadius: '50%',
                            height: '50px',
                            margin:'0 20px 0 0',
                        }}
                        />
                        <span>{item.username}</span>
                    </div>

                    <div>

                    </div>    
                </div>

                <p style={{ 'fontWeight' : 'bold' }} className='main-color m-0'>Subscribed</p>      
                

              </div>
              </>
              ))
              
            :
            
            ''
          }  

          {/*
          {inSubcribeArray.length && activePopUpTab == 'subscribed' ? <hr /> : '' } 
          */}


          


          {/*
          <label className={classes.CheckBoxContainer}>
            <div>
              Select All
            </div>
            <input type="checkbox" checked={selectAllCheck}  onChange={() => { toggleSelectAll() } } />
            <span className={classes.Checkmark}></span>
          </label>
          */}


          
          {/*new array*/}
          {
              sourceArray.map((item,i) => (
              <>  
              <div className='d-flex align-items-center justify-content-between mb-3'>
                <div className="d-flex align-items-center" key={item.id}>
                  <label className={`mb-4 ${classes.CheckBoxContainer}`}>
                    <input type="checkbox" checked={item.checked}
                    onChange={() => {
                      handleCheckboxChangeItems(item.id)
                    }}
                    />
                    <span className={classes.Checkmark}></span>
                  </label>
                    
                    <div className='mx-2'>
                        <img 
                        src={item.avatar ?? '/icons/manHolder.png' }
                        alt='image'
                        style={{
                            width: '50px',
                            borderRadius: '50%',
                            height: '50px',
                            margin:'0 20px 0 0',
                        }}
                        />
                        <span>{item.username}</span>
                    </div>

                    <div>

                    </div>    
                </div>

                { item.checked ? <p style={{ 'fontWeight' : 'bold' }} className='main-color m-0'> { tracking?.price == '0' ? 'Free' : tracking?.price + tracking?.user_currency } </p> : '' }        
                

              </div>
              </>
              ))
          }

          <hr />

          {
            tracking?.price && destinationArray.length
            ?
            <div className='d-flex justify-content-between' style={{ 'fontWeight' : 'bold' }}>
              <p>Total</p>
              <p className='main-color'>{ tracking?.price * destinationArray.length } { tracking?.user_currency }</p>
            </div>
            :
            ''
          }
      
        </Modal.Body>
        :
        <Modal.Body>
          {sourceArray.length ? (
            <h5 className="secondry-color mt-4 mb-3">
              Choose one or more family member
            </h5>
          ) : (
            <h5 className="secondry-color mt-4 mb-3">
              No member family found as all member are assigned to this
              initiative
            </h5>
          )}

          {/*new array*/}
          {sourceArray.map((item, i) => (
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              key={item.id}>
              <div>
                <img
                  src={item.avatar ?? '/icons/manHolder.png'}
                  alt="image"
                  style={{
                    width: '60px',
                    borderRadius: '50%',
                    height: '60px',
                    margin: '0 20px 0 0',
                  }}
                />
                <span>{item.username}</span>
              </div>

              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() => {
                      if( tracking?.type == 'initiative' ){
                        handleCheckboxChangeItems(item.id)
                      }else{
                        handleCheckboxChangeItemsForTracking(item.id)
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          ))}

          {destinationArray.length ? (
            <h5 className="secondry-color mt-4 mb-3">
              Selected family members
            </h5>
          ) : (
            ''
          )}

          {destinationArray.map(item => (
            <div
              className="d-flex justify-content-between align-items-center mb-3"
              key={item.id}>
              <div>
                <img
                  src={item.avatar ?? '/icons/manHolder.png'}
                  alt="image"
                  style={{
                    width: '60px',
                    borderRadius: '50%',
                    height: '60px',
                    margin: '0 20px 0 0',
                  }}
                />
                <span>{item.username}</span>
              </div>

              <div>
                <div>
                  <input
                    type="checkbox"
                    checked={item.checked}
                    onChange={() =>
                      handleDestinationCheckboxChangeItems(item.id)
                    }
                  />
                </div>
              </div>
            </div>
          ))}
          {/*new array*/}
          <hr />
          {tracking?.price && destinationArray.length ? (
            <div
              className="d-flex justify-content-between"
              style={{fontWeight: 'bold'}}>
              <p>Total</p>
              { unlimited ?
              <p>{tracking?.unlimited_price * destinationArray.length} {tracking?.user_currency}</p>
              :
              <p>{tracking?.price * destinationArray.length} {tracking?.user_currency}</p>
              }
              
            </div>
          ) : (
            ''
          )}
        </Modal.Body>
        }


        { tracking?.type == 'initiative' ?   
        <Modal.Footer className='pt-0'>
        <Row className='w-100'>
        <Col xs={{span: 12}} md={{span: 12}} className='p-0'>
          <label className={classes.CheckBoxContainer}>
            <div>
              I accept the{' '}
              <span
                onClick={ () => {
                  setShowAssignUsers(false)
                  setShowTrakingTerms(true)
                } }
                className={classes.TermsAndConditions}>
                Terms and conditions
              </span>
            </div>
            <input type="checkbox" checked={acceptTerms} id="accept" name="accept" onChange={(event) => {
              
              setAcceptTerms(!acceptTerms)
            } } />
            <span className={classes.Checkmark}></span>
          </label>
        </Col>
        </Row>
        <div className="w-100">
          <MainButton
            text={ tracking?.price ? 'Add To Cart' :  'Enroll Now' }
            onClick={() => {
              if( tracking?.price ){
                addToCart()
              }else{
                enroll()
              }
            }}
            disabled={destinationArray.length == 0 || acceptTerms != true }
          />
        </div>

        {/*    
        <div onClick={() => navigate('/cart')} className="w-100">
          <CancelButton text="View Cart" />
        </div>
        */}
      </Modal.Footer>
        :
        
        <Modal.Footer>
        <div className="w-100">
          <MainButton
            text={tracking?.price ? 'Add To Cart' : 'Enroll Now'}
            onClick={() => {
              if (tracking?.price) {
                addToCart();
              } else {
                enroll();
              }
            }}
            disabled={destinationArray.length == 0}
          />
        </div>

        <div onClick={() => setShowAssignUsers(false)} className="w-100">
          <CancelButton text="Cancel" />
        </div>
      </Modal.Footer>
        }
      </Modal>

      {/*choose price limitation pop up*/}
      <Modal
        show={showPriceLimitation}
        onHide={() => setShowPriceLimitation(false)}
        centered>
        <Modal.Body>
           
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <MainButton
              text={`Limited ( ${tracking?.price} ${ tracking?.user_currency } )`}
              onClick={() => {
                setUnLimited(false)
                setShowPriceLimitation(false)
                setShowAssignUsers(true)
              }}
            />
          </div>

          <div className="w-100">
            <MainButton
              text={`Unlimited ( ${tracking?.unlimited_price} ${tracking?.user_currency} )`}
              onClick={() => {
                setUnLimited(true)
                setShowPriceLimitation(false)
                setShowAssignUsers(true)
              }}
            />
          </div>

          <div onClick={() => setShowPriceLimitation(false)} className="w-100">
            <CancelButton text="Cancel" />
          </div>
        </Modal.Footer>
      </Modal>

      {/*assign user pop up*/}
      <Modal
        show={showTrakingTerms}
        onHide={() => {
          setShowAssignUsers(true) 
          setShowTrakingTerms(false)
        }}
        centered
        size='xl'>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          
            <ReactQuill theme="bubble" value={tracking?.terms_and_conditions} readOnly={true}  />
          
        </Modal.Body>

        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      {/*Show Error Msg*/}
      <Modal show={showErrorMsg} onHide={() => setShowErrorMsg(false)} centered>
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <h5 className="text-center mt-4 mb-3">
            This program is no longer available 
        </h5>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100" onClick={() => setShowErrorMsg(false)}>
            <MainButton text={'Confirm'} />
          </div>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default TrackingDetails;